import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const TopMenu = ({ onMenuClick }) => {

  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        height: '50px', // 전체 높이
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      >
      <Toolbar>
        <Button color="inherit" onClick={handleHomeClick} sx={{ flexGrow: 1 }}>
          <Typography
            sx={{ position: 'relative', // 배경 이미지 위에 오버레이로 배치
             }}
            variant="h6">
            Check Sea Stats & Let's travel to sea!
          </Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;